import { useRef, useCallback, MutableRefObject } from "react";
import { OAUTH_STATE_KEY, OAUTH_RESPONSE_KEY } from "../../constants/spotify";

const POPUP_HEIGHT = 800;
const POPUP_WIDTH = 600;

// generateState constructs a random 16 character string suitable to use for
// the Spotify OAuth flow.
const generateState = () => {
  let array = new Uint8Array(16);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => {
    return dec.toString(16).padStart(2, "0");
  }).join("");
};

// saveState stashes a state string in local storage.
// TODO: allow stashing multiple states based on sync attempt ID <27-01-24, bclarkx2> //
const saveState = (state: string) => {
  sessionStorage.setItem(OAUTH_STATE_KEY, state);
};

const removeState = () => {
  sessionStorage.removeItem(OAUTH_STATE_KEY);
};

// openPopup simply opens the specified URL in a well positioned popup window.
const openPopup = (url: string): Window | null => {
  const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
  const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
  return window.open(
    url,
    "Spotify Authorization",
    `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`
  );
};

const closePopup = (popupRef: MutableRefObject<Window | undefined>) => {
  popupRef.current?.close();
};

const cleanup = ({
  intervalRef,
  popupRef,
  handleMessageListener,
}: {
  intervalRef: MutableRefObject<ReturnType<typeof setInterval> | undefined>;
  popupRef: MutableRefObject<Window | undefined>;
  handleMessageListener: any;
}) => {
  clearInterval(intervalRef.current);
  closePopup(popupRef);
  removeState();
  window.removeEventListener("message", handleMessageListener);
};

// authCancelled describes the error returned from getAuthCode when the user
// closes the popup before completing the auth flow.
// Callers may want to respond to this error differently than if there were a
// 'true' error during the auth flow.
interface authCancelled {
  type: "cancelled";
  msg: string;
}

// isAuthCancelled is a type guard that checks whether an error is of type
// authCancelled.
export function isAuthCancelled(error: any): error is authCancelled {
  return (error as authCancelled).type === "cancelled";
}

// authError describes the error returned from getAuthCode when something goes
// wrong during the auth flow.
interface authError {
  type: "error";
  msg: string;
}

// isAuthError is a type guard that checks whether an error is of type authError.
export function isAuthError(error: any): error is authError {
  return (error as authError).type === "error";
}

// useAuthorize returns a getAuth hook that callers can use to acquire an authorization code.
export const useAuthorize = ({ authorizeURL }: { authorizeURL: string }) => {
  const popupRef: MutableRefObject<Window | undefined> = useRef();
  const intervalRef: MutableRefObject<
    ReturnType<typeof setInterval> | undefined
  > = useRef();

  const getAuthCode = useCallback<() => Promise<string>>(() => {
    return new Promise((resolve, reject) => {
      // Generate a random 16-character string and stash it in local storage.
      const state = generateState();
      saveState(state);

      const popup = openPopup(`${authorizeURL}?state=${state}`);
      if (popup) {
        popupRef.current = popup;
      }

      // This listener is going to be installed for when the popup sends us a
      // message through the window.
      async function handleMessageListener(message: MessageEvent) {
        const type = message && message.data && message.data.type;
        if (type === OAUTH_RESPONSE_KEY) {
          try {
            const data = message && message.data;
            const errorMaybe = data && data.error;
            if (errorMaybe) {
              reject({
                type: "error",
                msg: errorMaybe,
              });
              return;
            }

            // Attempt to resolve main promise with the acquired code.
            const code = data && data.payload && data.payload.code;
            resolve(code);
          } finally {
            cleanup({ intervalRef, popupRef, handleMessageListener });
          }
        }
      }
      window.addEventListener("message", handleMessageListener);

      // Set up an interval to check whether the popup is closed. The user could
      // close the popup before it sends us the window message we are listening
      // for, so we can't just rely on that listener to know that the popup is
      // done.
      intervalRef.current = setInterval(() => {
        const popupClosed =
          !popupRef.current ||
          !popupRef.current.window ||
          popupRef.current.window.closed;
        if (popupClosed) {
          // Popup was closed before completing auth...
          reject({
            type: "cancelled",
            msg: "popup closed too early",
          });
          clearInterval(intervalRef.current);
          removeState();
          window.removeEventListener("message", handleMessageListener);
        }
      }, 250);
    });
  }, [authorizeURL]);

  return [getAuthCode];
};
