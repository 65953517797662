import { useContext, useState } from "react";
import { BackendAPI } from "../../services";
import { User, Destination, UserStoreContext } from "../../stores/user";

export interface DestinationsListProps {
  backendClient: BackendAPI;
}

// DestinationList is a component that displays a list of destinations for a user
// and allows the user to add new destinations.
export const DestinationList = ({ backendClient }: DestinationsListProps) => {
  const { user, destinations } = useContext(UserStoreContext);

  // If the user isn't logged in yet, we don't want to show anything.
  if (user === null) {
    return <></>;
  }

  return (
    <div className="destinationList">
      <h2>Destinations:</h2>
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>ID</th>
            <th>Since</th>
          </tr>
        </thead>
        <tbody>
          {(destinations || []).map((destination) => (
            <DestinationRow key={destination.uuid} destination={destination} />
          ))}
        </tbody>
      </table>
      <DestinationAdder backendClient={backendClient} user={user} />
    </div>
  );
};

// DestinationRow is a component that displays a single Destination from the user's
// destinations list inside of a table row.
const DestinationRow = ({ destination }: { destination: Destination }) => {
  switch (destination.type) {
    case "download": {
      return (
        <tr>
          <td>Download</td>
          <td>{destination.uuid.substring(0, 8)}</td>
          <td>{destination.timestamp.toLocaleString()}</td>
        </tr>
      );
    }
    default: {
      return (
        <tr>
          <td>Unknown</td>
          <td></td>
          <td></td>
        </tr>
      );
    }
  }
};

// selectionState describes the current state of the destination adder component.
type selectionState =
  | selectionStateNone
  | selectionStateDownload
  | selectionStateError;

// selectionStateNone means that the user hasn't picked a destination type yet.
interface selectionStateNone {
  state: "none";
}

// selectionStateDownload means that the user is trying to create
// a new Download destination.
interface selectionStateDownload {
  state: "download";
}

// selectionStateError means that the user has encountered an error while
// trying to create a new destination.
interface selectionStateError {
  state: "error";
  error: string;
}

// DestinationAdder is a component that allows the user to add new destinations
// to their account.
const DestinationAdder = ({
  backendClient,
  user,
}: {
  backendClient: BackendAPI;
  user: User;
}) => {
  const [state, setState] = useState<selectionState>({ state: "none" });

  switch (state.state) {
    case "error":
    case "none": {
      return (
        <div>
          <h3>Add new destination</h3>
          {state.state === "error" && <p>{state.error}</p>}
          <select
            onChange={(e) => {
              if (e.target.value === "") {
                setState({ state: "none" });
              } else if (e.target.value === "download") {
                setState({ state: "download" });
              }
            }}
          >
            <option value="">Select destination type</option>
            <option value="download">Download</option>
          </select>
        </div>
      );
    }
    case "download": {
      return (
        <DownloadDestinationAdder
          backendClient={backendClient}
          user={user}
          setState={setState}
        />
      );
    }
  }
};

// DownloadDestinationAdderis a component that allows the user to add a
// new download destination to their account.
const DownloadDestinationAdder = ({
  backendClient,
  user,
  setState,
}: {
  backendClient: BackendAPI;
  user: User;
  setState: (state: selectionState) => void;
}) => {
  const { userStoreEventHandler } = useContext(UserStoreContext);

  // If you click add, we want to actually create the destination in the BE and then
  // inform the store.
  function handleAdd() {
    backendClient
      .createDownloadDestination(user.UUID)
      .then((response) => {
        switch (response.status) {
          case 200: {
            // Inform the store that we have a new destination.
            userStoreEventHandler({
              type: "addDestination",
              destination: {
                type: "download",
                uuid: response.data.destination.uuid,
                timestamp: new Date(response.data.destination.timestamp),
              },
            });
            // Return the user to the blank 'add a destination' state.
            setState({ state: "none" });
            break;
          }
          case 204: {
            // If the destination already exists, display an "error" to inform
            // the user.
            setState({
              state: "error",
              error: "Connection already has a download destination",
            });
            break;
          }
          default: {
            setState({
              state: "error",
              error: `Unknown error creating destination`,
            });
            console.log(
              "Unknown error creating download destination",
              response
            );
            break;
          }
        }
      })
      .catch((error) => {
        setState({
          state: "error",
          error: "Unknown error creating download destination",
        });
        console.log("Unknown error creating download destination", error);
      });
  }
  function handleCancel() {
    // If the user clicks cancel, we want to return them to the blank 'add a
    // destination' state.
    setState({ state: "none" });
  }

  return (
    <div>
      <h3>Add new Download destination</h3>
      <button onClick={handleAdd}>Add</button>
      <button onClick={handleCancel}>Cancel</button>
    </div>
  );
};
