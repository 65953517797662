import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AxiosError } from "axios";
import { BackendAPI } from "../../services";
import { SessionContext, LoginState } from "./Session";

// User represents the basic details about a user's account.
export interface User {
  UUID: string;
  Username: string;
}

// Connection represents a connection between a user and a third-party service.
export type Connection = SpotifyConnection;

// SpotifyConnection represents a connection between a user and their Spotify
// account.
export interface SpotifyConnection {
  type: "spotify";
  uuid: string;
  userUUID: string;
  spotifyUserID: string;
}

// Source represents a source of data that a user has connected to their
// account.
export type Source = SpotifyPlaylistsSource;

// SpotifyPlaylistsSource is a Source containing the full set of Spotify
// playlists associated with a Spotify connection.
export interface SpotifyPlaylistsSource {
  type: "spotifyPlaylists";
  uuid: string;
  connectionUUID: string;
  timestamp: Date;
}

export type Destination = DownloadDestination;

export interface DownloadDestination {
  type: "download";
  uuid: string;
  timestamp: Date;
}

// UserStoreContext makes details about the currently logged in user available
// to any children inside the UserStore.
export const UserStoreContext = createContext<{
  user: User | null;
  connections: Connection[] | null;
  sources: Source[] | null;
  destinations: Destination[] | null;
  userStoreEventHandler: (event: userStoreEvent) => void;
}>({
  user: null,
  connections: null,
  sources: null,
  destinations: null,
  userStoreEventHandler: () => {},
});

export interface UserStoreProps {
  backendClient: BackendAPI;
  children: any;
}

// userStoreEvents are events that affect the whole set of data stored in the
// UserStore. Consumers of the user store should use the userStoreEventHandler
// to send events to the UserStore to trigger loading and modification of data
// in the store.
type userStoreEvent =
  | userStoreEventLoadConnections
  | userStoreEventLoadSources
  | userStoreEventLoadDestinations
  | userStoreEventAddSource
  | userStoreEventAddDestination;

// userStoreEventLoadConnections is an event that triggers the UserStore to load
// all of the connections associated with the currently logged in user.
interface userStoreEventLoadConnections {
  type: "loadConnections";
  userUUID: string;
}

// userStoreEventLoadSources is an event that triggers the UserStore to load all
// of the sources associated with the currently logged in user.
interface userStoreEventLoadSources {
  type: "loadSources";
  userUUID: string;
}

// userStoreEventLoadDestinations is an event that triggers the UserStore to
// load all of the destinations associated with the currently logged in user.
interface userStoreEventLoadDestinations {
  type: "loadDestinations";
  userUUID: string;
}

// userStoreEventAddSource is an event that triggers the UserStore to add a new
// source to the currently logged in user's list of sources in the store.
// Note that a source passed in this way should already exist on the backend,
// this event JUST adds it to the store.
interface userStoreEventAddSource {
  type: "addSource";
  source: Source;
}

// userStoreEventAddDestination is an event that triggers the UserStore to add
// a new destination to the currently logged in user's list of destinations in
// the store.  Note that a destination passed in this way should already exist
// on the backend, this event JUST adds it to the store.
interface userStoreEventAddDestination {
  type: "addDestination";
  destination: Destination;
}

// UserStore is a browser store nested inside of a login Session that stores
// details about the currently logged in user. This is a convenience to any
// children of the UserStore, because those children can simply use the
// UserStoreContext to get and set information about the logged in user, rather
// than requesting that information from the BE themselves.
export const UserStore = ({ backendClient, children }: UserStoreProps) => {
  // The UserStore is entirely inside the context of a login Session.
  const { loginState, loginEventHandler } = useContext(SessionContext);

  const [user, setUser] = useState<User | null>(null);
  const [connections, setConnections] = useState<Connection[] | null>(null);
  const [sources, setSources] = useState<Source[] | null>(null);
  const [destinations, setDestinations] = useState<Destination[] | null>(null);

  const handleUserStoreEvent = useCallback(
    (event: userStoreEvent) => {
      switch (event.type) {
        // Any destination added with an addDestination event should just be tacked onto
        // the end of the list of destinations in the store.
        case "addDestination": {
          if (user === null) {
            return;
          }
          setDestinations((d) => [...(d ?? []), event.destination]);
          break;
        }
        // If we are asked to load destinations, we should pull them from the BE
        // and fully replace the list in the store.
        case "loadDestinations": {
          backendClient
            .getUserDestinations(event.userUUID)
            .then((response) => {
              setDestinations(
                response.data.destinations.map((d) => {
                  return {
                    type: "download",
                    uuid: d.uuid,
                    timestamp: new Date(d.timestamp),
                  };
                })
              );
            })
            .catch((error: AxiosError) => {
              setDestinations([]);
              switch (error.status) {
                case 401: {
                  loginEventHandler({
                    type: "unauthorized",
                    userUUID: event.userUUID,
                  });
                }
              }
              console.error("Error fetching user destinations", error);
            });
          break;
        }
        // Any source added with an addSource event should just be tacked onto
        // the end of the list of sources in the store.
        // TODO:  <18-02-24, bclarkx2> // deduplicate against existing sources?
        case "addSource": {
          if (user === null) {
            return;
          }
          setSources((s) => [...(s ?? []), event.source]);
          break;
        }
        // If we are asked to load sources, we should pull them from the BE
        // and fully replace the list in the store.
        case "loadSources": {
          if (user === null) {
            return;
          }

          backendClient
            .getUserSources(user.UUID)
            .then((response) => {
              setSources(
                response.data.sources.map((s) => {
                  return {
                    type: "spotifyPlaylists",
                    uuid: s.uuid,
                    connectionUUID: s.connectionUUID,
                    timestamp: new Date(s.timestamp),
                  };
                })
              );
            })
            .catch((error: AxiosError) => {
              setSources([]);
              switch (error.status) {
                case 401: {
                  loginEventHandler({
                    type: "unauthorized",
                    userUUID: user.UUID,
                  });
                }
              }
              console.error("Error fetching user sources", error);
            });
          break;
        }
        // If we are asked to load connections, we should pull them from the BE
        // and fully replace the list in the store.
        case "loadConnections": {
          if (user === null) {
            return;
          }

          backendClient
            .getUserConnections(user.UUID)
            .then((response) => {
              setConnections(
                response.data.connections.map((c) => {
                  return {
                    type: "spotify",
                    uuid: c.uuid,
                    userUUID: c.userUUID,
                    spotifyUserID: c.spotifyUserID,
                  };
                })
              );
            })
            .catch((error: AxiosError) => {
              setConnections([]);
              switch (error.status) {
                case 401: {
                  loginEventHandler({
                    type: "unauthorized",
                    userUUID: user.UUID,
                  });
                }
              }
              console.error("Error fetching user connections", error);
            });
          break;
        }
      }
    },
    [backendClient, loginEventHandler, user]
  );

  // Every time the login state changes, we want to adjust the user store.
  useEffect(() => {
    function updateForLoginState(loginState: LoginState) {
      switch (loginState.status) {
        case "loggedIn": {
          if (user !== null) {
            return;
          }
          // If we are logged in, great! Let's get some details about the user
          // that is supposedly already logged in. In theory, the browser has
          // an httpOnly cookie right now with a valid session ID for this
          // user. Let's see if that's true!
          backendClient
            .getUser(loginState.userUUID)
            .then((response) => {
              // It is! We'll update the stored user accordingly.
              setUser({
                UUID: response.data.user.uuid,
                Username: response.data.user.username,
              });
              handleUserStoreEvent({
                type: "loadDestinations",
                userUUID: response.data.user.uuid,
              });
            })
            .catch((reason: AxiosError) => {
              // It's not! Let's be clear to our children that there is no user
              // data to work from, and then let's report this fact back up to
              // the Session. The session may require the user to
              // re-authenticate at this point.
              setUser(null);
              setConnections(null);
              setSources(null);
              setDestinations(null);
              switch (reason.status) {
                case 401: {
                  loginEventHandler({
                    type: "unauthorized",
                    userUUID: loginState.userUUID,
                  });
                }
              }
            });
          break;
        }
        case "loggedOut": {
          // If we think we're logged out, make sure all children are aware
          // there is no user data.
          setUser(null);
          setConnections(null);
          setSources(null);
          setDestinations(null);
          break;
        }
        case "registrationFailed":
        case "failed": {
          // If we think we just failed to log in, make sure all children are
          // aware that there is no user data.
          setUser(null);
          setConnections(null);
          setSources(null);
          setDestinations(null);
          break;
        }
      }
    }
    updateForLoginState(loginState);
  }, [
    backendClient,
    loginEventHandler,
    loginState,
    user,
    handleUserStoreEvent,
  ]);

  return (
    <UserStoreContext.Provider
      value={{
        user: user,
        connections: connections,
        sources: sources,
        destinations: destinations,
        userStoreEventHandler: handleUserStoreEvent,
      }}
    >
      {children}
    </UserStoreContext.Provider>
  );
};
