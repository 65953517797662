import { BackendAPI } from "../../services";
import { useAuthorize } from "../../hooks/spotify";
import { useReducer, createContext } from "react";
import { AxiosError } from "axios";

export interface SessionProps {
  backendClient: BackendAPI;
  authorizeURL: string;
  children: any;
}

// Initially, the user starts as logged out.
// TODO: start off as unset  <04-02-24, bclarkx2> //
const initialState: LoginState = {
  status: "loggedOut",
};

// SessionContext makes the current loginState available to any children. Any
// changes to login status should be reported as LoginEvents to the
// loginEventHandler. The Session will then take care of properly interacting
// with the BE and loginState to handle the login event.
export const SessionContext = createContext<{
  loginState: LoginState;
  loginEventHandler: (event: LoginEvent) => void;
}>({
  loginState: { status: "loggedOut" },
  loginEventHandler: (_: LoginEvent) => {},
});

// Session is a store modelling a login session for a user. All children within
// the Session can consider themselves to be a part of the login session.
export const Session = ({
  backendClient,
  authorizeURL,
  children,
}: SessionProps) => {
  // loginState is the ultimate arbiter of a user's current login state.
  const [loginState, dispatch] = useReducer(stateReducer, initialState);

  // getSpotifyAuthCode will prompt the user to log in to Spotify and then
  // return the auth code.
  const [getSpotifyAuthCode] = useAuthorize({ authorizeURL });

  // handleLoginEvent is the primary tool for modiyfing the user's login state.
  function handleLoginEvent(event: LoginEvent) {
    switch (event.type) {
      case "attemptRegister": {
        getSpotifyAuthCode()
          .then((code) => {
            backendClient
              .register({
                auth: {
                  spotify: {
                    code: code,
                  },
                },
              })
              .then((response) => {
                // On success, tell the UI we are logged in as the new user.
                dispatch({
                  type: "success",
                  userUUID: response.data.user.uuid,
                });
              })
              .catch((error: AxiosError<{ error: string }>) => {
                dispatch({
                  type: "registrationFailed",
                  error: error.message,
                });
              });
          })
          .catch((error) => {
            dispatch({
              type: "registrationFailed",
              error: error.message,
            });
          });

        break;
      }
      case "attemptLogin": {
        // First off, set the UI to show we are currently pending a login
        // attempt.
        dispatch({
          type: "pending",
        });

        getSpotifyAuthCode()
          .then((code) => {
            // Next, actually try to log in.
            backendClient
              .login({
                auth: {
                  spotify: {
                    code: code,
                  },
                },
              })
              .then((response) => {
                // On success, tell the UI that we are logged in!
                dispatch({
                  type: "success",
                  userUUID: response.data.userUUID,
                });
              })
              .catch((error: AxiosError<{ error: string }>) => {
                // On failure, tell the UI that there was a problem.
                dispatch({
                  type: "failed",
                  error: error.message,
                });
              });
          })
          .catch((error) => {
            dispatch({
              type: "failed",
              error: error.message,
            });
          });

        break;
      }
      case "logout": {
        // Inform UI that a logout attempt is in motion.
        dispatch({
          type: "loggingOut",
          userUUID: event.userUUID,
        });

        // Now, actually try to log out.
        backendClient
          .logout(event.userUUID)
          .then(() => {
            // Inform UI we are logged out.
            dispatch({
              type: "loggedOut",
            });
          })
          .catch((reason: AxiosError) => {
            console.log(`Unexpected error logging out: ${reason.message}`);
          });
        break;
      }
    }
  }

  return (
    <SessionContext.Provider
      value={{
        loginState: loginState,
        loginEventHandler: handleLoginEvent,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

// LoginState represents all of the logical states the page can be in with
// regards to account login.
export type LoginState =
  | loginStateLoggedIn
  | loginStateLoggedOut
  | loginStateLoggingIn
  | loginStateLoggingOut
  | loginStateFailed
  | loginStateRegistrationFailed
  | loginStateUnset;

interface loginStateLoggedIn {
  status: "loggedIn";
  userUUID: string;
}

interface loginStateLoggedOut {
  status: "loggedOut";
}

interface loginStateLoggingIn {
  status: "loggingIn";
}

interface loginStateLoggingOut {
  status: "loggingOut";
  userUUID: string;
}

interface loginStateFailed {
  status: "failed";
  error: string;
}

interface loginStateRegistrationFailed {
  status: "registrationFailed";
  error: string;
}

interface loginStateUnset {
  status: "unset";
}

// loginAction is a specific change to the loginState that is being triggered.
// Sometimes, a single LoginEvent may trigger multiple loginActions (for
// example, when you initiate a log in, you'll first move to a state where you
// are in the middle of logging in and then to a state where you have
// successfully logged in. These actions help trigger the changes between those
// states).
type loginAction =
  | loginActionPending
  | loginActionSuccess
  | loginActionFailed
  | loginActionRegistrationFailed
  | loginActionLoggingOut
  | loginActionLoggedOut;

interface loginActionPending {
  type: "pending";
}

interface loginActionSuccess {
  type: "success";
  userUUID: string;
}

interface loginActionFailed {
  type: "failed";
  error: string;
}

interface loginActionRegistrationFailed {
  type: "registrationFailed";
  error: string;
}

interface loginActionLoggingOut {
  type: "loggingOut";
  userUUID: string;
}

interface loginActionLoggedOut {
  type: "loggedOut";
}

// LoginEvents are top level changes to a user's login status that can be
// triggered from one of the Session's children.
export type LoginEvent =
  | loginEventAttemptLogin
  | loginEventUnauthorized
  | loginEventLogout
  | loginEventAttemptRegister;

interface loginEventAttemptLogin {
  type: "attemptLogin";
}

interface loginEventUnauthorized {
  type: "unauthorized";
  userUUID: string;
}

interface loginEventLogout {
  type: "logout";
  userUUID: string;
}

interface loginEventAttemptRegister {
  type: "attemptRegister";
}

// stateReducer updates the current LoginState based on the specified
// loginAction.
function stateReducer(state: LoginState, action: loginAction): LoginState {
  switch (action.type) {
    case "pending": {
      return {
        status: "loggingIn",
      };
    }
    case "failed": {
      return {
        status: "failed",
        error: action.error,
      };
    }
    case "registrationFailed": {
      return {
        status: "registrationFailed",
        error: action.error,
      };
    }
    case "success": {
      return {
        status: "loggedIn",
        userUUID: action.userUUID,
      };
    }
    case "loggingOut": {
      return {
        status: "loggingOut",
        userUUID: action.userUUID,
      };
    }
    case "loggedOut": {
      return {
        status: "loggedOut",
      };
    }
    default: {
      return state;
    }
  }
}
