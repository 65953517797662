import { useContext, useReducer, useEffect, Dispatch } from "react";
import { BackendAPI } from "../../services";
import {
  UserStoreContext,
  Connection,
  SpotifyConnection,
} from "../../stores/user";
import { AxiosError } from "axios";

export interface ConnectionListProps {
  backendClient: BackendAPI;
}

type Profile = Spotify;

type Spotify = SpotifyProfile | SpotifyProfileError;

interface SpotifyProfile {
  type: "spotify";
  state: "ok";
  connectionUUID: string;
  spotifyUserID: string;
  displayName: string;
  url: string;
}

interface SpotifyProfileError {
  type: "spotify";
  state: "error";
  connectionUUID: string;
  error: string;
}

const initialProfiles: Profile[] = [];

type profileAction = profileActionReset | profileActionValidate;

interface profileActionReset {
  type: "reset";
}

type profileActionValidate =
  | profileActionValidateSpotify
  | profileActionValidateSpotifyError;

interface profileActionValidateSpotify {
  type: "validateSpotify";
  profile: SpotifyProfile;
}

interface profileActionValidateSpotifyError {
  type: "validateSpotifyError";
  connectionUUID: string;
  error: string;
}

function profileReducer(profiles: Profile[], action: profileAction): Profile[] {
  switch (action.type) {
    case "reset": {
      return [];
    }
    case "validateSpotify": {
      return profiles
        .filter((p) => {
          return p.connectionUUID !== action.profile.connectionUUID;
        })
        .concat(action.profile);
    }
    case "validateSpotifyError": {
      return profiles
        .filter((p) => {
          return p.connectionUUID !== action.connectionUUID;
        })
        .concat({
          type: "spotify",
          state: "error",
          connectionUUID: action.connectionUUID,
          error: action.error,
        });
    }
  }
}

export const ConnectionList = ({ backendClient }: ConnectionListProps) => {
  const [profiles, dispatch] = useReducer(profileReducer, initialProfiles);

  const { user, connections, userStoreEventHandler } =
    useContext(UserStoreContext);

  useEffect(() => {
    if (user && connections === null) {
      userStoreEventHandler({
        type: "loadConnections",
        userUUID: user.UUID,
      });
    }
    if (!user) {
      dispatch({ type: "reset" });
    }
  }, [userStoreEventHandler, user, connections]);

  if (user === null) {
    return <></>;
  }

  if (connections === null) {
    return <></>;
  }

  return (
    <div className="connectionList">
      <h2>Connections:</h2>
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>ID</th>
            <th>Sync</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {connections.map((connection) => (
            <ConnectionRow
              key={connection.uuid}
              backendClient={backendClient}
              connection={connection}
              profile={profiles.find(
                (p) =>
                  p.type === "spotify" && p.connectionUUID === connection.uuid
              )}
              dispatch={dispatch}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ConnectionRow = ({
  backendClient,
  connection,
  profile,
  dispatch,
}: {
  backendClient: BackendAPI;
  connection: Connection;
  profile: Profile | undefined;
  dispatch: Dispatch<profileAction>;
}) => {
  switch (connection.type) {
    case "spotify": {
      return (
        <tr>
          <td>Spotify</td>
          <td>{connection.spotifyUserID}</td>
          <td>
            <SpotifyValidateButton
              backendClient={backendClient}
              connection={connection}
              dispatch={dispatch}
            ></SpotifyValidateButton>
          </td>
          <td>
            {profile &&
              profile.type === "spotify" &&
              profile.state === "ok" && (
                <a href={profile.url}>{profile.displayName}</a>
              )}
            {profile &&
              profile.type === "spotify" &&
              profile.state === "error" && <span>{profile.error}</span>}
          </td>
        </tr>
      );
    }
    default: {
      return (
        <tr>
          <td>Unknown</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }
  }
};

const SpotifyValidateButton = ({
  connection,
  backendClient,
  dispatch,
}: {
  connection: SpotifyConnection;
  backendClient: BackendAPI;
  dispatch: Dispatch<profileAction>;
}) => {
  function handleValidate() {
    backendClient
      .getUserSpotifyProfile(connection.userUUID, connection.uuid)
      .then((response) => {
        dispatch({
          type: "validateSpotify",
          profile: {
            type: "spotify",
            state: "ok",
            connectionUUID: connection.uuid,
            spotifyUserID: response.data.profile.spotifyUserID,
            displayName: response.data.profile.displayName,
            url: response.data.profile.url,
          },
        });
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: "validateSpotifyError",
          connectionUUID: connection.uuid,
          error: error.message,
        });
      });
  }

  return (
    <button
      className="spotifyConnectionValidateButton"
      onClick={handleValidate}
    >
      Sync
    </button>
  );
};
