import "./App.css";
import { Pinger } from "./components/ping/Ping";
import { Syncer } from "./components/sync/Sync";
import { AuthPopup as SpotifyAuthPopup } from "./components/spotify/Popup";
import { Dashboard } from "./components/login";
import { ConnectionList } from "./components/connections";
import { SourcesList } from "./components/sources";
import { DestinationList } from "./components/destinations";
import { Session, UserStore } from "./stores/user";
import { BackendAPI } from "./services";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios, { AxiosRequestConfig } from "axios";

const backendURL =
  window.__RUNTIME_ENV__.BACKEND_API_URL || "http://localhost:3000/api";

const backendConfig: AxiosRequestConfig = {
  baseURL: backendURL,
};

const axiosClient = axios.create(backendConfig);

function App() {
  const backendClient = new BackendAPI(
    {
      basePath: backendURL,
      isJsonMime: () => false,
    },
    undefined,
    axiosClient
  );

  // TODO: move this to a context to avoid passing it around everywhere?
  // <11-02-24, bclarkx2> //
  const authorizeURL = `${backendURL}/spotify/authorize`;

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <div className="App">
                <header className="App-header">Welcome to SeeSpotSync!</header>
                <div className="App-content">
                  <Session
                    backendClient={backendClient}
                    authorizeURL={authorizeURL}
                  >
                    <UserStore backendClient={backendClient}>
                      <Dashboard></Dashboard>
                      <ConnectionList
                        backendClient={backendClient}
                      ></ConnectionList>
                      <SourcesList backendClient={backendClient}></SourcesList>
                      <DestinationList
                        backendClient={backendClient}
                      ></DestinationList>
                      <Pinger backendClient={backendClient}></Pinger>
                      <Syncer
                        backendClient={backendClient}
                        authorizeURL={authorizeURL}
                      ></Syncer>
                    </UserStore>
                  </Session>
                </div>
              </div>
            }
          />
          <Route path="/callback/spotify" element={<SpotifyAuthPopup />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
