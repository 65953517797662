import { useContext } from "react";
import { LoginState, LoginEvent, SessionContext } from "../../stores/user";
import { User, UserStoreContext } from "../../stores/user";

// Dashboard is a user dashboard that shows information about the currently
// logged in user, and allows the user to log in and out.
//
// Any login events generated by this component are reported to the
// loginEventHandler available in the closest SessionContext.
export const Dashboard = () => {
  const { loginState, loginEventHandler } = useContext(SessionContext);
  const { user } = useContext(UserStoreContext);

  return (
    <div>
      <Header state={loginState} user={user}></Header>
      <Login
        loginState={loginState}
        loginEventHandler={loginEventHandler}
      ></Login>
      <Register
        loginState={loginState}
        loginEventHandler={loginEventHandler}
      ></Register>
    </div>
  );
};

// Header displays information about the login status.
const Header = ({ state, user }: { state: LoginState; user: User | null }) => {
  switch (state.status) {
    case "loggedOut": {
      return <>{"Logged out"}</>;
    }
    case "loggingIn": {
      return <>{`Logging in...`}</>;
    }
    case "loggingOut": {
      return <>{`Logging out...`}</>;
    }
    case "loggedIn": {
      // Can only show user details if the profile's been loaded.
      if (user === null) {
        return <>{`Welcome! Fetching your profile`}</>;
      } else {
        return <>{`Welcome, ${user.Username}!`}</>;
      }
    }
    case "failed": {
      return <>{`Error logging in user: ${state.error}`}</>;
    }
    case "registrationFailed": {
      return <>{`Error registering: ${state.error}`}</>;
    }
    default: {
      return <>{"Unknown error logging in, please try again"}</>;
    }
  }
};

// Login is a component that lets the user log in and out of the site.
const Login = ({
  loginState,
  loginEventHandler,
}: {
  loginState: LoginState;
  loginEventHandler: (e: LoginEvent) => void;
}) => {
  function handleLogin() {
    loginEventHandler({
      type: "attemptLogin",
    });
  }

  function handleLogout(userUUID: string) {
    loginEventHandler({
      type: "logout",
      userUUID: userUUID,
    });
  }

  switch (loginState.status) {
    case "loggingIn":
    case "loggingOut":
      return <></>;
    case "loggedIn":
      return (
        <div>
          <button
            className="logoutButton"
            onClick={() => handleLogout(loginState.userUUID)}
          >
            Log out
          </button>
        </div>
      );
    default:
      return (
        <div>
          <button className="loginButton" onClick={() => handleLogin()}>
            Log in with Spotify
          </button>
        </div>
      );
  }
};

// Register is a component that lets a new user attempt to register with the
// site.
const Register = ({
  loginState,
  loginEventHandler,
}: {
  loginState: LoginState;
  loginEventHandler: (e: LoginEvent) => void;
}) => {
  function handleRegister() {
    // all we have to do when this is clicked is funnel the message up to the
    // SessionContext this component is a part of.
    loginEventHandler({
      type: "attemptRegister",
    });
  }

  // Only show the register button if the user is not currently logged in.
  switch (loginState.status) {
    case "loggedOut":
    case "failed":
    case "registrationFailed": {
      return (
        <button className="registerButton" onClick={handleRegister}>
          Register with Spotify
        </button>
      );
    }
    default: {
      return <></>;
    }
  }
};
