import { BackendAPI } from "../../services";
import { useReducer } from "react";
import {
  useAuthorize,
  isAuthError,
  isAuthCancelled,
} from "../../hooks/spotify";

export interface SyncerProps {
  backendClient: BackendAPI;
  authorizeURL: string;
}

// These hold the state of the sync list. They have to be outside of the sync
// list itself because we don't want them to be reset when the sync list
// re-renders itself.
// TODO: move these into the Syncer itself? <27-01-24, bclarkx2> //
const initialSyncs: syncState[] = [];
let nextID = 0;

// Syncer is a component with a button that triggers new syncs, and then a
// dynamic list of ongoing syncs and their statuses. The status of each
// displayed sync updates automatically.
export const Syncer = ({ backendClient, authorizeURL }: SyncerProps) => {
  const [syncs, dispatch] = useReducer(syncReducer, initialSyncs);
  const [getAuthCode] = useAuthorize({
    authorizeURL: authorizeURL,
  });

  function dispatchError(syncID: number, error: any) {
    if (isAuthCancelled(error)) {
      dispatch({
        id: syncID,
        type: "cancelled",
        reason: error.msg,
      });
    } else if (isAuthError(error)) {
      dispatch({
        id: syncID,
        type: "error",
        error: error.msg,
      });
    } else {
      dispatch({
        id: syncID,
        type: "error",
        error: error,
      });
    }
  }

  // When a new sync is triggered, we want to start the auth process on a sync
  // with an ID not in use yet.
  function triggerNewSync() {
    const syncID = nextID++;

    // Alert the sync list that a new sync has begun
    dispatch({
      id: syncID,
      type: "new",
    });

    getAuthCode()
      .then((code) => {
        // Exchange auth code for access token
        backendClient
          .spotifyToken({ code: code })
          .then((response) => {
            if (!response.data.token) {
              dispatchError(syncID, "missing token");
            }
            const token = response.data.token!;

            // Get Spotify profile using access token
            backendClient
              .spotifyMe(token)
              .then((response) => {
                // When we have the profile data, dispatch the sync result to the UI.
                dispatch({
                  id: syncID,
                  type: "synced",
                  payload: response.data,
                });
              })
              .catch((reason) => {
                dispatchError(syncID, reason);
              });
          })
          .catch((reason) => {
            dispatchError(syncID, reason);
          });
      })
      .catch((reason) => {
        dispatchError(syncID, reason);
      });
  }

  return (
    <div>
      <h2>Sync With Spotify</h2>
      <TriggerSyncButton
        triggerNewSync={() => triggerNewSync()}
      ></TriggerSyncButton>
      <SyncList syncs={syncs} />
    </div>
  );
};

// TriggerSyncButton is a simple button that just passes the click up to its
// parent's triggerNewSync handler.
function TriggerSyncButton(props: any) {
  return (
    <button className="triggerSyncButton" onClick={props.triggerNewSync}>
      Trigger sync
    </button>
  );
}

// SyncList holds a dynamic record of all the current sync attempts. Each
// syncState it is supposed to hold is displayed to the user as a SyncRecord.
function SyncList({ syncs }: { syncs: syncState[] }) {
  return (
    <div className="syncList">
      {syncs.map((s) => (
        <SyncRecord key={s.id} sync={s}></SyncRecord>
      ))}
    </div>
  );
}

// SyncRecord is a display component that shows the user the state of a sync
// using a bulleted list.
function SyncRecord({ sync }: { sync: syncState }) {
  const display = ((sync: syncState) => {
    switch (sync.state) {
      case "pending": {
        return (
          <div>
            <ul>
              <li>Status: pending</li>
              <li>Sync initiated: {sync.initiatedAt.toISOString()}</li>
            </ul>
          </div>
        );
      }
      case "success": {
        return (
          <div>
            <ul>
              <li>Status: success</li>
              <li>Sync initiated: {sync.initiatedAt.toISOString()}</li>
              <li>Sync completed: {sync.syncedAt.toISOString()}</li>
              <li>
                Time elasped:{" "}
                {sync.syncedAt.valueOf() - sync.initiatedAt.valueOf()}
              </li>
              <li>Display name: {sync.displayName}</li>
              <li>
                Profile: <a href={sync.profileURL}>{sync.profileURL}</a>
              </li>
            </ul>
          </div>
        );
      }
      case "error": {
        return (
          <div>
            <ul>
              <li>Status: error</li>
              <li>Sync initiated: {sync.initiatedAt.toISOString()}</li>
              <li>Sync errored: {sync.errorAt.toISOString()}</li>
              <li>
                Time elasped:{" "}
                {sync.errorAt.valueOf() - sync.initiatedAt.valueOf()}
              </li>
              <li>Error: {sync.error}</li>
            </ul>
          </div>
        );
      }
      case "cancelled": {
        return (
          <div>
            <ul>
              <li>Status: cancelled</li>
              <li>Sync initiated: {sync.initiatedAt.toISOString()}</li>
              <li>Sync errored: {sync.cancelledAt.toISOString()}</li>
              <li>
                Time elasped:{" "}
                {sync.cancelledAt.valueOf() - sync.initiatedAt.valueOf()}
              </li>
              <li>Reason: {sync.reason}</li>
            </ul>
          </div>
        );
      }
      default: {
        return "err: unknown sync state";
      }
    }
  })(sync);

  return (
    <div>
      <h3>Sync attempt</h3>
      {display}
    </div>
  );
}

// syncState is a data type recording the current state of a sync attempt.
// Different sync states are represented with discrete state types making up
// the overall syncState union type.
type syncState =
  | syncStatePending
  | syncStateSuccess
  | syncStateError
  | syncStateCancelled;

interface syncStatePending {
  id: number;
  state: "pending";
  initiatedAt: Date;
}

interface syncStateSuccess {
  id: number;
  state: "success";
  initiatedAt: Date;
  syncedAt: Date;
  displayName: string;
  profileURL: string;
}

interface syncStateError {
  id: number;
  state: "error";
  initiatedAt: Date;
  errorAt: Date;
  error: string;
}

interface syncStateCancelled {
  id: number;
  state: "cancelled";
  initiatedAt: Date;
  cancelledAt: Date;
  reason: string;
}

// syncAction represents a change to the sync state of an individual sync
// attempt. The sync attempt being changed is denoted by the id of the
// syncAction, the type of the action represents what sort of action happened,
// and each action type contains extra fields detailing the specifics of what
// happened.
type syncAction =
  | syncActionNew
  | syncActionSynced
  | syncActionError
  | syncActionCancelled;

interface syncActionNew {
  id: number;
  type: "new";
}

interface syncActionSynced {
  id: number;
  type: "synced";
  payload: {
    displayName: string;
    url: string;
  };
}

interface syncActionError {
  id: number;
  type: "error";
  error: string;
}

interface syncActionCancelled {
  id: number;
  type: "cancelled";
  reason: string;
}

// syncReducer updates a list of syncStates with the result of an individual
// syncAction.
function syncReducer(syncs: syncState[], action: syncAction): syncState[] {
  switch (action.type) {
    case "new": {
      return [
        ...syncs,
        {
          id: action.id,
          state: "pending",
          initiatedAt: new Date(),
        },
      ];
    }
    case "synced": {
      return syncs.map((s) => {
        if (s.id === action.id) {
          return {
            id: s.id,
            state: "success",
            initiatedAt: s.initiatedAt,
            syncedAt: new Date(),
            displayName: action.payload.displayName,
            profileURL: action.payload.url,
          };
        } else {
          return s;
        }
      });
    }
    case "error": {
      return syncs.map((s) => {
        if (s.id === action.id) {
          return {
            id: s.id,
            state: "error",
            initiatedAt: s.initiatedAt,
            errorAt: new Date(),
            error: action.error,
          };
        } else {
          return s;
        }
      });
    }
    case "cancelled": {
      return syncs.map((s) => {
        if (s.id === action.id) {
          return {
            id: s.id,
            state: "cancelled",
            initiatedAt: s.initiatedAt,
            cancelledAt: new Date(),
            reason: action.reason,
          };
        } else {
          return s;
        }
      });
    }
    default: {
      return syncs;
    }
  }
}
