import {BackendAPI} from '../../services';
import {useState} from 'react';

export interface PingerProps {
  backendClient: BackendAPI;
}

export const Pinger = ({backendClient}: PingerProps) => {
  const [code, setCode] = useState('?');
  const [time, setTime] = useState(new Date().toISOString());

  const handleClick = (): void => {
    backendClient.spotifyPing()
      .then((result) => {
        setCode(result.data.code === 200 ? 'OK' : 'Error');
      })
      .catch((reason) => console.log(reason))
      .finally(() => {
        setTime(new Date().toISOString());
      });
  }

  return <>
      <div>
        <h2>Check Spotify Stats</h2>
        <Button onClick={() => handleClick()}></Button>
        <Status code={code} time={time}></Status>
      </div>
  </>
}

function Button(props: any) {
	return (
	<button className='pingButton' onClick={props.onClick}>
		Ping Spotify
	</button>
	)
}

function Status(props: any) {
	return (
    <div>
      <p>Status: {props.code}</p>
      <p>Last Checked: {props.time}</p>
    </div>
	)
}

