import { useEffect } from "react";
import { OAUTH_STATE_KEY, OAUTH_RESPONSE_KEY } from "../../constants/spotify";

// checkState checks if the state value provided matches the one currently
// stashed in session storage.
const checkState = (receivedState: string) => {
  const state = sessionStorage.getItem(OAUTH_STATE_KEY);
  return state === receivedState;
};

const queryToObject = (query: string) => {
  const params = new URLSearchParams(query);
  return Object.fromEntries(params.entries());
};

// AuthPopup is the contents of a popup window that responds to the redirect
// from Spotify's auth server. On success, it passes the auth code from Spotify
// through to the main window through a window message. On error, it passes the
// error through to the main window through a window message.
export const AuthPopup = () => {
  const component = <div>Loading...</div>;

  useEffect(() => {
    const payload = queryToObject(window.location.href.split("?")[1]);
    const state = payload?.state;
    const error = payload?.error;

    console.log(payload);

    if (!window.opener) {
      throw new Error("No window opener");
    }

    if (error) {
      console.log(`error: ${error}`);
      window.opener.postMessage({
        type: OAUTH_RESPONSE_KEY,
        error:
          decodeURI(error) ||
          "OAuth error: an error has occurred redirecting to Spotify",
      });
    } else if (state && checkState(state)) {
      console.log(`state OK: ${state}`);
      window.opener.postMessage({
        type: OAUTH_RESPONSE_KEY,
        payload,
      });
    } else {
      console.log(`state mismatch: ${state}`);
      window.opener.postMessage({
        type: OAUTH_RESPONSE_KEY,
        error: "OAuth error: state mismatch",
      });
    }
  }, []);

  return component;
};
