import { useContext, useEffect, useState } from "react";
import { BackendAPI } from "../../services";
import { User, Connection, Source, UserStoreContext } from "../../stores/user";

export interface SourcesListProps {
  backendClient: BackendAPI;
}

// SourcesList is a component that displays a list of sources for a user
// and allows the user to add new sources.
export const SourcesList = ({ backendClient }: SourcesListProps) => {
  const { user, connections, sources, userStoreEventHandler } =
    useContext(UserStoreContext);

  // When this component loads, we want to ask the user store to load the
  // user's source.
  useEffect(() => {
    if (user && sources === null) {
      userStoreEventHandler({
        type: "loadSources",
        userUUID: user.UUID,
      });
    }
  }, [userStoreEventHandler, user, sources]);

  // If the user isn't logged in yet, we don't want to show anything.
  if (user === null) {
    return <></>;
  }

  // If the user is logged in but we haven't loaded their sources yet, we
  // don't want to show anything.
  if (sources === null) {
    return <></>;
  }

  return (
    <div className="sourceList">
      <h2>Sources:</h2>
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>ID</th>
            <th>Connection</th>
            <th>Since</th>
          </tr>
        </thead>
        <tbody>
          {sources.map((source) => (
            <SourceRow
              key={source.uuid}
              source={source}
              connection={
                connections?.find(
                  (c) => c.uuid === source.connectionUUID
                ) as Connection
              }
            />
          ))}
        </tbody>
      </table>
      <SourceAdder
        backendClient={backendClient}
        user={user}
        connections={connections}
      />
    </div>
  );
};

// SourceRow is a component that displays a single Source from the user's
// sources list inside of a table row.
const SourceRow = ({
  source,
  connection,
}: {
  source: Source;
  connection: Connection;
}) => {
  switch (source.type) {
    case "spotifyPlaylists": {
      return (
        <tr>
          <td>Spotify Playlists</td>
          <td>{source.uuid.substring(0, 12)}</td>
          <td>{connection.spotifyUserID}</td>
          <td>{source.timestamp.toLocaleString()}</td>
        </tr>
      );
    }
    default: {
      return (
        <tr>
          <td>Unknown</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }
  }
};

// selectionState describes the current state of the source adder component.
type selectionState =
  | selectionStateNone
  | selectionStateSpotifyPlaylists
  | selectionStateError;

// selectionStateNone means that the user hasn't picked a source type yet.
interface selectionStateNone {
  state: "none";
}

// selectionStateSpotifyPlaylists means that the user is trying to create
// a new Spotify Playlists source.
interface selectionStateSpotifyPlaylists {
  state: "spotifyPlaylists";
}

// selectionStateError means that the user has encountered an error while
// trying to create a new source.
interface selectionStateError {
  state: "error";
  error: string;
}

// SourceAdder is a component that allows the user to add new sources to
// their account.
const SourceAdder = ({
  backendClient,
  user,
  connections,
}: {
  backendClient: BackendAPI;
  user: User;
  connections: Connection[] | null;
}) => {
  const [state, setState] = useState<selectionState>({ state: "none" });

  // Early out if we don't have any connections loaded.
  // TODO: <18-02-24, bclarkx2> // Should we just load these?
  if (connections === null) {
    return <span>Cannot add new source without connections</span>;
  }

  switch (state.state) {
    case "error":
    case "none": {
      return (
        <div>
          <h3>Add new source</h3>
          {state.state === "error" && <p>{state.error}</p>}
          <select
            onChange={(e) => {
              if (e.target.value === "") {
                setState({ state: "none" });
              } else if (e.target.value === "spotifyPlaylists") {
                setState({ state: "spotifyPlaylists" });
              }
            }}
          >
            <option value="">Select source type</option>
            <option value="spotifyPlaylists">Spotify Playlists</option>
          </select>
        </div>
      );
    }
    case "spotifyPlaylists": {
      return (
        <SpotifyPlaylistsSourceAdder
          backendClient={backendClient}
          user={user}
          connections={connections}
          setState={setState}
        />
      );
    }
  }
};

// SpotifyPlaylistsSourceAdder is a component that allows the user to add a
// new Spotify Playlists source to their account.
const SpotifyPlaylistsSourceAdder = ({
  backendClient,
  user,
  connections,
  setState,
}: {
  backendClient: BackendAPI;
  user: User;
  connections: Connection[];
  setState: (state: selectionState) => void;
}) => {
  const [connectionUUID, setConnectionUUID] = useState<string>("");
  const { userStoreEventHandler } = useContext(UserStoreContext);

  // If you click add, we want to actually create the source in the BE and then
  // inform the store.
  function handleAdd() {
    if (connectionUUID === "") {
      setState({ state: "none" });
      return;
    }

    backendClient
      .createSpotifyPlaylistsSource(user.UUID, connectionUUID)
      .then((response) => {
        switch (response.status) {
          case 200: {
            // Inform the store that we have a new source.
            userStoreEventHandler({
              type: "addSource",
              source: {
                type: "spotifyPlaylists",
                uuid: response.data.source.uuid,
                connectionUUID: response.data.source.connectionUUID,
                timestamp: new Date(response.data.source.timestamp),
              },
            });
            // Return the user to the blank 'add a source' state.
            setState({ state: "none" });
            break;
          }
          case 204: {
            // If the source already exists, display an "error" to inform the
            // user.
            setState({
              state: "error",
              error: "Connection already has a spotify playlists source",
            });
            break;
          }
          default: {
            setState({
              state: "error",
              error: `Unknown error creating source`,
            });
            console.log(
              "Unknown error creating spotify playlist source",
              response
            );
            break;
          }
        }
      })
      .catch((error) => {
        setState({
          state: "error",
          error: "Unknown error creating source",
        });
        console.log("Unknown error creating spotify playlist source", error);
      });
  }
  function handleCancel() {
    // If the user clicks cancel, we want to return them to the blank 'add a
    // source' state.
    setState({ state: "none" });
  }

  return (
    <div>
      <h3>Add new Spotify Playlists source</h3>
      <select
        value={connectionUUID}
        onChange={(e) => setConnectionUUID(e.target.value)}
      >
        <option value="">Select connection</option>
        {connections.map((c) => (
          <option key={c.uuid} value={c.uuid}>
            {c.spotifyUserID}
          </option>
        ))}
      </select>
      <button onClick={handleAdd}>Add</button>
      <button onClick={handleCancel}>Cancel</button>
    </div>
  );
};
