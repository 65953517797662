/* tslint:disable */
/* eslint-disable */
/**
 * SpotifySync API
 * REST API to interact with the SpotifySync application back end.
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: bclarkx2@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthMethods
 */
export interface AuthMethods {
    /**
     * 
     * @type {AuthMethodsAuth}
     * @memberof AuthMethods
     */
    'auth': AuthMethodsAuth;
}
/**
 * 
 * @export
 * @interface AuthMethodsAuth
 */
export interface AuthMethodsAuth {
    /**
     * 
     * @type {SpotifyTokenRequest}
     * @memberof AuthMethodsAuth
     */
    'spotify': SpotifyTokenRequest;
}
/**
 * 
 * @export
 * @interface CreateDownloadDestination200Response
 */
export interface CreateDownloadDestination200Response {
    /**
     * 
     * @type {CreateDownloadDestination200ResponseDestination}
     * @memberof CreateDownloadDestination200Response
     */
    'destination': CreateDownloadDestination200ResponseDestination;
}
/**
 * @type CreateDownloadDestination200ResponseDestination
 * @export
 */
export type CreateDownloadDestination200ResponseDestination = DownloadDestination;

/**
 * 
 * @export
 * @interface CreateSpotifyPlaylistsSource200Response
 */
export interface CreateSpotifyPlaylistsSource200Response {
    /**
     * 
     * @type {CreateSpotifyPlaylistsSource200ResponseSource}
     * @memberof CreateSpotifyPlaylistsSource200Response
     */
    'source': CreateSpotifyPlaylistsSource200ResponseSource;
}
/**
 * @type CreateSpotifyPlaylistsSource200ResponseSource
 * @export
 */
export type CreateSpotifyPlaylistsSource200ResponseSource = SpotifyPlaylistsSource;

/**
 * 
 * @export
 * @interface DownloadDestination
 */
export interface DownloadDestination {
    /**
     * 
     * @type {string}
     * @memberof DownloadDestination
     */
    'type'?: DownloadDestinationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DownloadDestination
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof DownloadDestination
     */
    'timestamp': string;
}

export const DownloadDestinationTypeEnum = {
    Download: 'download'
} as const;

export type DownloadDestinationTypeEnum = typeof DownloadDestinationTypeEnum[keyof typeof DownloadDestinationTypeEnum];

/**
 * 
 * @export
 * @interface GetUser200Response
 */
export interface GetUser200Response {
    /**
     * 
     * @type {GetUser200ResponseUser}
     * @memberof GetUser200Response
     */
    'user': GetUser200ResponseUser;
}
/**
 * 
 * @export
 * @interface GetUser200ResponseUser
 */
export interface GetUser200ResponseUser {
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUser
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200ResponseUser
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface GetUserConnections200Response
 */
export interface GetUserConnections200Response {
    /**
     * 
     * @type {Array<GetUserConnections200ResponseConnectionsInner>}
     * @memberof GetUserConnections200Response
     */
    'connections': Array<GetUserConnections200ResponseConnectionsInner>;
}
/**
 * @type GetUserConnections200ResponseConnectionsInner
 * @export
 */
export type GetUserConnections200ResponseConnectionsInner = SpotifyConnection;

/**
 * 
 * @export
 * @interface GetUserDestinations200Response
 */
export interface GetUserDestinations200Response {
    /**
     * 
     * @type {Array<GetUserDestinations200ResponseDestinationsInner>}
     * @memberof GetUserDestinations200Response
     */
    'destinations': Array<GetUserDestinations200ResponseDestinationsInner>;
}
/**
 * @type GetUserDestinations200ResponseDestinationsInner
 * @export
 */
export type GetUserDestinations200ResponseDestinationsInner = DownloadDestination;

/**
 * 
 * @export
 * @interface GetUserSources200Response
 */
export interface GetUserSources200Response {
    /**
     * 
     * @type {Array<GetUserSources200ResponseSourcesInner>}
     * @memberof GetUserSources200Response
     */
    'sources': Array<GetUserSources200ResponseSourcesInner>;
}
/**
 * @type GetUserSources200ResponseSourcesInner
 * @export
 */
export type GetUserSources200ResponseSourcesInner = SpotifyPlaylistsSource;

/**
 * 
 * @export
 * @interface GetUserSpotifyProfile200Response
 */
export interface GetUserSpotifyProfile200Response {
    /**
     * 
     * @type {SpotifyProfile}
     * @memberof GetUserSpotifyProfile200Response
     */
    'profile': SpotifyProfile;
}
/**
 * 
 * @export
 * @interface GetUserSpotifyProfile404Response
 */
export interface GetUserSpotifyProfile404Response {
    /**
     * 
     * @type {string}
     * @memberof GetUserSpotifyProfile404Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetUserSpotifyProfile404ResponseDetails}
     * @memberof GetUserSpotifyProfile404Response
     */
    'details'?: GetUserSpotifyProfile404ResponseDetails;
}
/**
 * 
 * @export
 * @interface GetUserSpotifyProfile404ResponseDetails
 */
export interface GetUserSpotifyProfile404ResponseDetails {
    /**
     * 
     * @type {string}
     * @memberof GetUserSpotifyProfile404ResponseDetails
     */
    'connectionUUID': string;
}
/**
 * 
 * @export
 * @interface Login200Response
 */
export interface Login200Response {
    /**
     * 
     * @type {string}
     * @memberof Login200Response
     */
    'userUUID': string;
}
/**
 * 
 * @export
 * @interface Login404Response
 */
export interface Login404Response {
    /**
     * 
     * @type {string}
     * @memberof Login404Response
     */
    'error': string;
    /**
     * 
     * @type {Login200Response}
     * @memberof Login404Response
     */
    'details': Login200Response;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {object}
     * @memberof ModelError
     */
    'error': object;
}
/**
 * 
 * @export
 * @interface Ping
 */
export interface Ping {
    /**
     * 
     * @type {number}
     * @memberof Ping
     */
    'code': number;
}
/**
 * 
 * @export
 * @interface Register200Response
 */
export interface Register200Response {
    /**
     * 
     * @type {User}
     * @memberof Register200Response
     */
    'user': User;
}
/**
 * 
 * @export
 * @interface Register401Response
 */
export interface Register401Response {
    /**
     * 
     * @type {string}
     * @memberof Register401Response
     */
    'error': string;
    /**
     * 
     * @type {Register401ResponseDetails}
     * @memberof Register401Response
     */
    'details': Register401ResponseDetails;
}
/**
 * 
 * @export
 * @interface Register401ResponseDetails
 */
export interface Register401ResponseDetails {
    /**
     * 
     * @type {string}
     * @memberof Register401ResponseDetails
     */
    'method': string;
}
/**
 * 
 * @export
 * @interface Register409Response
 */
export interface Register409Response {
    /**
     * 
     * @type {string}
     * @memberof Register409Response
     */
    'error': string;
    /**
     * 
     * @type {Register409ResponseDetails}
     * @memberof Register409Response
     */
    'details': Register409ResponseDetails;
}
/**
 * 
 * @export
 * @interface Register409ResponseDetails
 */
export interface Register409ResponseDetails {
    /**
     * 
     * @type {string}
     * @memberof Register409ResponseDetails
     */
    'spotifyUserID'?: string;
}
/**
 * 
 * @export
 * @interface SpotifyConnection
 */
export interface SpotifyConnection {
    /**
     * 
     * @type {string}
     * @memberof SpotifyConnection
     */
    'type'?: SpotifyConnectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SpotifyConnection
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof SpotifyConnection
     */
    'userUUID': string;
    /**
     * 
     * @type {string}
     * @memberof SpotifyConnection
     */
    'spotifyUserID': string;
}

export const SpotifyConnectionTypeEnum = {
    Spotify: 'spotify'
} as const;

export type SpotifyConnectionTypeEnum = typeof SpotifyConnectionTypeEnum[keyof typeof SpotifyConnectionTypeEnum];

/**
 * 
 * @export
 * @interface SpotifyMe200Response
 */
export interface SpotifyMe200Response {
    /**
     * 
     * @type {string}
     * @memberof SpotifyMe200Response
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof SpotifyMe200Response
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface SpotifyPlaylistsSource
 */
export interface SpotifyPlaylistsSource {
    /**
     * 
     * @type {string}
     * @memberof SpotifyPlaylistsSource
     */
    'type'?: SpotifyPlaylistsSourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SpotifyPlaylistsSource
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof SpotifyPlaylistsSource
     */
    'connectionUUID': string;
    /**
     * 
     * @type {string}
     * @memberof SpotifyPlaylistsSource
     */
    'timestamp': string;
}

export const SpotifyPlaylistsSourceTypeEnum = {
    SpotifyPlaylists: 'spotifyPlaylists'
} as const;

export type SpotifyPlaylistsSourceTypeEnum = typeof SpotifyPlaylistsSourceTypeEnum[keyof typeof SpotifyPlaylistsSourceTypeEnum];

/**
 * 
 * @export
 * @interface SpotifyProfile
 */
export interface SpotifyProfile {
    /**
     * 
     * @type {string}
     * @memberof SpotifyProfile
     */
    'spotifyUserID': string;
    /**
     * 
     * @type {string}
     * @memberof SpotifyProfile
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof SpotifyProfile
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface SpotifyToken200Response
 */
export interface SpotifyToken200Response {
    /**
     * 
     * @type {string}
     * @memberof SpotifyToken200Response
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface SpotifyTokenRequest
 */
export interface SpotifyTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof SpotifyTokenRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Configure a new Destination of type Download for the logged in user.
         * @summary Create a new download destination.
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDownloadDestination: async (userUUID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUUID' is not null or undefined
            assertParamExists('createDownloadDestination', 'userUUID', userUUID)
            const localVarPath = `/users/{userUUID}/destinations/download`
                .replace(`{${"userUUID"}}`, encodeURIComponent(String(userUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new spotify playlists source for a given Spotify connection.
         * @summary Create new spotify playlists source.
         * @param {string} userUUID The user UUID
         * @param {string} connectionUUID The connection UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSpotifyPlaylistsSource: async (userUUID: string, connectionUUID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUUID' is not null or undefined
            assertParamExists('createSpotifyPlaylistsSource', 'userUUID', userUUID)
            // verify required parameter 'connectionUUID' is not null or undefined
            assertParamExists('createSpotifyPlaylistsSource', 'connectionUUID', connectionUUID)
            const localVarPath = `/users/{userUUID}/connections/spotify/{connectionUUID}/sources/playlists`
                .replace(`{${"userUUID"}}`, encodeURIComponent(String(userUUID)))
                .replace(`{${"connectionUUID"}}`, encodeURIComponent(String(connectionUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve details about a specific user account.
         * @summary Get user
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userUUID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUUID' is not null or undefined
            assertParamExists('getUser', 'userUUID', userUUID)
            const localVarPath = `/users/{userUUID}`
                .replace(`{${"userUUID"}}`, encodeURIComponent(String(userUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all connections for a specific user account.
         * @summary Get user\'s connections
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserConnections: async (userUUID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUUID' is not null or undefined
            assertParamExists('getUserConnections', 'userUUID', userUUID)
            const localVarPath = `/users/{userUUID}/connections`
                .replace(`{${"userUUID"}}`, encodeURIComponent(String(userUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all destinations for a specific user account.
         * @summary Get user\'s destinations
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDestinations: async (userUUID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUUID' is not null or undefined
            assertParamExists('getUserDestinations', 'userUUID', userUUID)
            const localVarPath = `/users/{userUUID}/destinations`
                .replace(`{${"userUUID"}}`, encodeURIComponent(String(userUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all sources for a specific user account.
         * @summary Get user\'s sources
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSources: async (userUUID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUUID' is not null or undefined
            assertParamExists('getUserSources', 'userUUID', userUUID)
            const localVarPath = `/users/{userUUID}/sources`
                .replace(`{${"userUUID"}}`, encodeURIComponent(String(userUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the Spotify profile of a user\'s Spotify connection
         * @summary Get the Spotify profile of a user\'s Spotify connection
         * @param {string} userUUID The user UUID
         * @param {string} connectionUUID The connection UUID of a spotify connection owned by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSpotifyProfile: async (userUUID: string, connectionUUID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUUID' is not null or undefined
            assertParamExists('getUserSpotifyProfile', 'userUUID', userUUID)
            // verify required parameter 'connectionUUID' is not null or undefined
            assertParamExists('getUserSpotifyProfile', 'connectionUUID', connectionUUID)
            const localVarPath = `/users/{userUUID}/connections/spotify/{connectionUUID}/profile`
                .replace(`{${"userUUID"}}`, encodeURIComponent(String(userUUID)))
                .replace(`{${"connectionUUID"}}`, encodeURIComponent(String(connectionUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Make a login attempt for the specified SSS user.
         * @summary Log in to user account
         * @param {AuthMethods} authMethods 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (authMethods: AuthMethods, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authMethods' is not null or undefined
            assertParamExists('login', 'authMethods', authMethods)
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authMethods, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Log out of the currently logged in SSS user.
         * @summary Log out.
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (userUUID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUUID' is not null or undefined
            assertParamExists('logout', 'userUUID', userUUID)
            const localVarPath = `/users/{userUUID}/logout`
                .replace(`{${"userUUID"}}`, encodeURIComponent(String(userUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Make an attempt to register a new SSS user account.
         * @summary Register a new SSS user account.
         * @param {AuthMethods} authMethods 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (authMethods: AuthMethods, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authMethods' is not null or undefined
            assertParamExists('register', 'authMethods', authMethods)
            const localVarPath = `/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authMethods, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch app authorization page from Spotify
         * @summary Fetch app authorization page from Spotify
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotifyAuthorize: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/spotify/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Spotify profile of currently logged in user
         * @summary Get Spotify profile
         * @param {string} xSpotifyAuthorization Spotify OAuth access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotifyMe: async (xSpotifyAuthorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xSpotifyAuthorization' is not null or undefined
            assertParamExists('spotifyMe', 'xSpotifyAuthorization', xSpotifyAuthorization)
            const localVarPath = `/spotify/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xSpotifyAuthorization != null) {
                localVarHeaderParameter['X-Spotify-Authorization'] = String(xSpotifyAuthorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check whether spotify.com is up
         * @summary Ping spotify.com
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotifyPing: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/spotify/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exchange Spotify auth code for Spotify access token
         * @summary Get Spotify app token
         * @param {SpotifyTokenRequest} spotifyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotifyToken: async (spotifyTokenRequest: SpotifyTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'spotifyTokenRequest' is not null or undefined
            assertParamExists('spotifyToken', 'spotifyTokenRequest', spotifyTokenRequest)
            const localVarPath = `/spotify/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(spotifyTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Configure a new Destination of type Download for the logged in user.
         * @summary Create a new download destination.
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDownloadDestination(userUUID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDownloadDestination200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDownloadDestination(userUUID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create new spotify playlists source for a given Spotify connection.
         * @summary Create new spotify playlists source.
         * @param {string} userUUID The user UUID
         * @param {string} connectionUUID The connection UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSpotifyPlaylistsSource(userUUID: string, connectionUUID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSpotifyPlaylistsSource200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSpotifyPlaylistsSource(userUUID, connectionUUID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve details about a specific user account.
         * @summary Get user
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userUUID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userUUID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all connections for a specific user account.
         * @summary Get user\'s connections
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserConnections(userUUID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserConnections200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserConnections(userUUID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all destinations for a specific user account.
         * @summary Get user\'s destinations
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDestinations(userUUID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDestinations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDestinations(userUUID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all sources for a specific user account.
         * @summary Get user\'s sources
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSources(userUUID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserSources200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSources(userUUID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the Spotify profile of a user\'s Spotify connection
         * @summary Get the Spotify profile of a user\'s Spotify connection
         * @param {string} userUUID The user UUID
         * @param {string} connectionUUID The connection UUID of a spotify connection owned by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSpotifyProfile(userUUID: string, connectionUUID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserSpotifyProfile200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSpotifyProfile(userUUID, connectionUUID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Make a login attempt for the specified SSS user.
         * @summary Log in to user account
         * @param {AuthMethods} authMethods 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(authMethods: AuthMethods, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Login200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(authMethods, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Log out of the currently logged in SSS user.
         * @summary Log out.
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(userUUID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(userUUID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Make an attempt to register a new SSS user account.
         * @summary Register a new SSS user account.
         * @param {AuthMethods} authMethods 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(authMethods: AuthMethods, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Register200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(authMethods, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch app authorization page from Spotify
         * @summary Fetch app authorization page from Spotify
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotifyAuthorize(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotifyAuthorize(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Spotify profile of currently logged in user
         * @summary Get Spotify profile
         * @param {string} xSpotifyAuthorization Spotify OAuth access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotifyMe(xSpotifyAuthorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotifyMe200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotifyMe(xSpotifyAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check whether spotify.com is up
         * @summary Ping spotify.com
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotifyPing(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ping>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotifyPing(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exchange Spotify auth code for Spotify access token
         * @summary Get Spotify app token
         * @param {SpotifyTokenRequest} spotifyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotifyToken(spotifyTokenRequest: SpotifyTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotifyToken200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotifyToken(spotifyTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Configure a new Destination of type Download for the logged in user.
         * @summary Create a new download destination.
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDownloadDestination(userUUID: string, options?: any): AxiosPromise<CreateDownloadDestination200Response> {
            return localVarFp.createDownloadDestination(userUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new spotify playlists source for a given Spotify connection.
         * @summary Create new spotify playlists source.
         * @param {string} userUUID The user UUID
         * @param {string} connectionUUID The connection UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSpotifyPlaylistsSource(userUUID: string, connectionUUID: string, options?: any): AxiosPromise<CreateSpotifyPlaylistsSource200Response> {
            return localVarFp.createSpotifyPlaylistsSource(userUUID, connectionUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve details about a specific user account.
         * @summary Get user
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userUUID: string, options?: any): AxiosPromise<GetUser200Response> {
            return localVarFp.getUser(userUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all connections for a specific user account.
         * @summary Get user\'s connections
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserConnections(userUUID: string, options?: any): AxiosPromise<GetUserConnections200Response> {
            return localVarFp.getUserConnections(userUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all destinations for a specific user account.
         * @summary Get user\'s destinations
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDestinations(userUUID: string, options?: any): AxiosPromise<GetUserDestinations200Response> {
            return localVarFp.getUserDestinations(userUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all sources for a specific user account.
         * @summary Get user\'s sources
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSources(userUUID: string, options?: any): AxiosPromise<GetUserSources200Response> {
            return localVarFp.getUserSources(userUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the Spotify profile of a user\'s Spotify connection
         * @summary Get the Spotify profile of a user\'s Spotify connection
         * @param {string} userUUID The user UUID
         * @param {string} connectionUUID The connection UUID of a spotify connection owned by the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSpotifyProfile(userUUID: string, connectionUUID: string, options?: any): AxiosPromise<GetUserSpotifyProfile200Response> {
            return localVarFp.getUserSpotifyProfile(userUUID, connectionUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Make a login attempt for the specified SSS user.
         * @summary Log in to user account
         * @param {AuthMethods} authMethods 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(authMethods: AuthMethods, options?: any): AxiosPromise<Login200Response> {
            return localVarFp.login(authMethods, options).then((request) => request(axios, basePath));
        },
        /**
         * Log out of the currently logged in SSS user.
         * @summary Log out.
         * @param {string} userUUID The user UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(userUUID: string, options?: any): AxiosPromise<object> {
            return localVarFp.logout(userUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Make an attempt to register a new SSS user account.
         * @summary Register a new SSS user account.
         * @param {AuthMethods} authMethods 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(authMethods: AuthMethods, options?: any): AxiosPromise<Register200Response> {
            return localVarFp.register(authMethods, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch app authorization page from Spotify
         * @summary Fetch app authorization page from Spotify
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotifyAuthorize(options?: any): AxiosPromise<void> {
            return localVarFp.spotifyAuthorize(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Spotify profile of currently logged in user
         * @summary Get Spotify profile
         * @param {string} xSpotifyAuthorization Spotify OAuth access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotifyMe(xSpotifyAuthorization: string, options?: any): AxiosPromise<SpotifyMe200Response> {
            return localVarFp.spotifyMe(xSpotifyAuthorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Check whether spotify.com is up
         * @summary Ping spotify.com
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotifyPing(options?: any): AxiosPromise<Ping> {
            return localVarFp.spotifyPing(options).then((request) => request(axios, basePath));
        },
        /**
         * Exchange Spotify auth code for Spotify access token
         * @summary Get Spotify app token
         * @param {SpotifyTokenRequest} spotifyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotifyToken(spotifyTokenRequest: SpotifyTokenRequest, options?: any): AxiosPromise<SpotifyToken200Response> {
            return localVarFp.spotifyToken(spotifyTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Configure a new Destination of type Download for the logged in user.
     * @summary Create a new download destination.
     * @param {string} userUUID The user UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createDownloadDestination(userUUID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createDownloadDestination(userUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new spotify playlists source for a given Spotify connection.
     * @summary Create new spotify playlists source.
     * @param {string} userUUID The user UUID
     * @param {string} connectionUUID The connection UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSpotifyPlaylistsSource(userUUID: string, connectionUUID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createSpotifyPlaylistsSource(userUUID, connectionUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve details about a specific user account.
     * @summary Get user
     * @param {string} userUUID The user UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUser(userUUID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUser(userUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all connections for a specific user account.
     * @summary Get user\'s connections
     * @param {string} userUUID The user UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserConnections(userUUID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserConnections(userUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all destinations for a specific user account.
     * @summary Get user\'s destinations
     * @param {string} userUUID The user UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserDestinations(userUUID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserDestinations(userUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all sources for a specific user account.
     * @summary Get user\'s sources
     * @param {string} userUUID The user UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserSources(userUUID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserSources(userUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the Spotify profile of a user\'s Spotify connection
     * @summary Get the Spotify profile of a user\'s Spotify connection
     * @param {string} userUUID The user UUID
     * @param {string} connectionUUID The connection UUID of a spotify connection owned by the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserSpotifyProfile(userUUID: string, connectionUUID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserSpotifyProfile(userUUID, connectionUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Make a login attempt for the specified SSS user.
     * @summary Log in to user account
     * @param {AuthMethods} authMethods 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public login(authMethods: AuthMethods, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).login(authMethods, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Log out of the currently logged in SSS user.
     * @summary Log out.
     * @param {string} userUUID The user UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logout(userUUID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).logout(userUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Make an attempt to register a new SSS user account.
     * @summary Register a new SSS user account.
     * @param {AuthMethods} authMethods 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public register(authMethods: AuthMethods, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).register(authMethods, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch app authorization page from Spotify
     * @summary Fetch app authorization page from Spotify
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public spotifyAuthorize(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).spotifyAuthorize(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Spotify profile of currently logged in user
     * @summary Get Spotify profile
     * @param {string} xSpotifyAuthorization Spotify OAuth access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public spotifyMe(xSpotifyAuthorization: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).spotifyMe(xSpotifyAuthorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check whether spotify.com is up
     * @summary Ping spotify.com
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public spotifyPing(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).spotifyPing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exchange Spotify auth code for Spotify access token
     * @summary Get Spotify app token
     * @param {SpotifyTokenRequest} spotifyTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public spotifyToken(spotifyTokenRequest: SpotifyTokenRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).spotifyToken(spotifyTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


